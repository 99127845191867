import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = () => (
  <Layout>
    <Seo title="Contact" />
    <h1 className='contact-us-title text-glow'>Contact Us</h1>
    <h5 className='contact-us-subtitle subtitle-text-glow'>Let's Build Something Great!</h5>
    <div className='contact-us-button'>
      
      <a style={{color: 'black'}}href='mailto:joe@blipp.org?subject=Business Inquiry: I want to make an iPhone Application!
            &body=Hi Clean Apps,%0D%0A%0D%0A

            My name is: %0D%0A
            I want to make an iPhone application that: %0D%0A
            I need you to:
            %0D%0A
            %0D%0A
            <Examples: Develop an application, design an application, advise me on how to get started>
            %0D%0A
            %0D%0A
            Thanks,'>Contact Us</a>
    </div>
  </Layout>
)

export default Contact
